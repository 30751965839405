<template>
  <div>
    <Title title="Build and Deployment How To">
    </Title>
      <v-card class="mx-auto mt-5" tile>
      </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import tokenAxios from "@/components/tokenAxios";
import Title from "@/components/Title";

export default {
  name: 'HowTo',
  components: { Title },
  computed: {
    ...mapState(["isMobile", "user_info"]),
  },
  data: () => ({
    API_SERVER: process.env.VUE_APP_API_SERVER,
  }),
  created() {
  },
  methods: {
  },
}
</script>